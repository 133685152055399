import LogRocket from 'logrocket';

if (
  process.env.VUE_APP_ENV === 'production' ||
  process.env.VUE_APP_ENV === 'staging' ||
  process.env.VUE_APP_ENV === 'development'
) {
  LogRocket.init('2e4brc/citix-manager', {
    release: '1.0.1',
  });
}
