import axios from '../../../axios/index.js';
import store from '../../../../store/store.js';
import UserRepository from '@/repositories/UserRepository.js';
// Token Refresh
let isAlreadyFetchingAccessToken = false;
let subscribers = [];

function onAccessTokenFetched(access_token) {
  subscribers = subscribers.filter((callback) => callback(access_token));
}

function addSubscriber(callback) {
  subscribers.push(callback);
}

export default {
  init() {
    axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        // const { config, response: { status } } = error
        const { config, response } = error;
        const originalRequest = config;

        // if (status === 401) {
        if (response && response.status === 401) {
          if (!isAlreadyFetchingAccessToken) {
            isAlreadyFetchingAccessToken = true;
            store.dispatch('auth/fetchAccessToken').then((access_token) => {
              isAlreadyFetchingAccessToken = false;
              onAccessTokenFetched(access_token);
            });
          }

          const retryOriginalRequest = new Promise((resolve) => {
            addSubscriber((access_token) => {
              originalRequest.headers.Authorization = 'Bearer ' + access_token;
              resolve(axios(originalRequest));
            });
          });
          return retryOriginalRequest;
        }
        return Promise.reject(error);
      }
    );
  },
  login(userDetails, registerType) {
    return UserRepository(axios).login({
      ...userDetails,
      register_type: registerType,
    });
  },
  registerUser(name, surname, email, pwd, masterPassword) {
    return axios.post('/api/register', {
      first_name: name,
      last_name: surname,
      email: email,
      password: pwd,
      register_type: 'admin',
      master_password: masterPassword,
    });
  },
  refreshToken() {
    return axios.post('/api/auth/refresh-token', {
      accessToken: localStorage.getItem('accessToKen'),
    });
  },
};
