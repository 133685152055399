// ~/plugins/socket.io.js
import Vue from 'vue';
import { io } from 'socket.io-client';
import VueSocketIOExt from 'vue-socket.io-extended';
const authorization = 'Bearer ' + localStorage.getItem('accessToken') || '';
const socket = io(process.env.VUE_APP_SERVICE_NOTIFY_URL || '', {
  extraHeaders: {
    Authorization: authorization,
  },
});
Vue.use(VueSocketIOExt, socket);
