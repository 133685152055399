import Vue from 'vue';
import numeral from 'numeral';

Vue.filter('currencyFormat', function (value) {
  return numeral(value).format('$0,0');
});
Vue.filter('percentFormat', function (value) {
  return numeral(value / 100).format('0%');
});
Vue.filter('capitalize', function (value) {
  if (!value) return '';
  value = value.toString();
  let arr = value.split(' ');
  let capitalized_array = [];
  arr.forEach((word) => {
    let capitalized = word.charAt(0).toUpperCase() + word.slice(1);
    capitalized_array.push(capitalized);
  });
  return capitalized_array.join(' ');
});

Vue.filter('title', function (value, replacer = '_') {
  if (!value) return '';
  value = value.toString();

  let arr = value.split(replacer);
  let capitalized_array = [];
  arr.forEach((word) => {
    let capitalized = word.charAt(0).toUpperCase() + word.slice(1);
    capitalized_array.push(capitalized);
  });
  return capitalized_array.join(' ');
});

Vue.filter('truncate', function (value, limit) {
  return value.substring(0, limit);
});

Vue.filter('tailing', function (value, tail) {
  return value + tail;
});

Vue.filter('time', function (value, is24HrFormat = false) {
  if (value) {
    const date = new Date(Date.parse(value));
    let hours = date.getHours();
    const min = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
    if (!is24HrFormat) {
      const time = hours > 12 ? 'AM' : 'PM';
      hours = hours % 12 || 12;
      return hours + ':' + min + ' ' + time;
    }
    return hours + ':' + min;
  }
});

Vue.filter('date', function (value, fullDate = false) {
  value = String(value);
  const date = value.slice(8, 10).trim();
  const month = value.slice(4, 7).trim();
  const year = value.slice(11, 15);

  if (!fullDate) return date + ' ' + month;
  else return date + ' ' + month + ' ' + year;
});

Vue.filter('month', function (val, showYear = true) {
  val = String(val);

  const regx = /\w+\s(\w+)\s\d+\s(\d+)./;
  if (!showYear) {
    return regx.exec(val)[1];
  } else {
    return regx.exec(val)[1] + ' ' + regx.exec(val)[2];
  }
});

Vue.filter('csv', function (value) {
  return value.join(', ');
});

Vue.filter('filter_tags', function (value) {
  return value.replace(/<\/?[^>]+(>|$)/g, '');
});

Vue.filter('k_formatter', function (num) {
  return num > 999 ? (num / 1000).toFixed(1) + 'k' : num;
});

Vue.filter('toFixed', function (price, limit) {
  return price.toFixed(limit);
});

Vue.filter('toUSD', function (price) {
  return `$${price}`;
});

Vue.filter('typeAccount', function (type) {
  switch (type) {
    case 'BANK':
      return 'Banco';
    case 'CASH':
      return 'Efectivo';
  }
});

Vue.filter('typeTransaction', function (type) {
  switch (type) {
    case 'RECHARGE':
      return 'Recarga';
    case 'DISCHARGE':
      return 'Descarga';
  }
});

Vue.filter('typeTransaction', function (type) {
  switch (type) {
    case 'RECHARGE':
      return 'Recarga';
    case 'DISCHARGE':
      return 'Descarga';
    case 'INCOME':
      return 'Ingreso';
    case 'TRANSFER':
      return 'Transferencia';
    case 'EXPEND':
      return 'Egreso';
    case 'WITHDRAW':
      return 'Retiro';
  }
});
Vue.filter('typecommerce', function (type) {
  switch (type) {
    case 'business':
      return 'Negocio';
    case 'online_store':
      return 'Tienda Online';
    case 'restaurant_pastry':
      return 'Pastelería';
  }
});

Vue.filter('allyStatus', function (type) {
  switch (type) {
    case 'ENABLED':
      return 'ACT';
    case 'INACTIVE':
      return 'INA';
    case 'INACTIVE_TRAINING':
      return 'IPC';
    case 'INACTIVE_OBSERVATION':
      return 'IP0';
    case 'DISABLED':
      return 'DES';
  }
});

Vue.filter('deliverymanHistoryStatus', function (type) {
  switch (type) {
    case 'ENABLED':
      return 'Activo';
    case 'INACTIVE':
      return 'Inactivo';
    case 'INACTIVE_TRAINING':
      return 'Inactivo por capacitación';
    case 'INACTIVE_OBSERVATION':
      return 'Inactivo por observación';
    case 'DISABLED':
      return 'Deshabilitado';
  }
});

Vue.filter('dataStatus', function (type) {
  switch (type) {
    case 'verified':
      return 'Verificado';
    case 'waiting_verification':
      return 'No verificado';
  }
});

Vue.filter('inventoryTypes', function (type) {
  const labels = {
    STOCK: 'Stock',
    PRICE: 'Precio',
    NAMING: 'Nombres',
    CASH: 'Efectivo',
    POINTS: 'Puntos',
    CASH_AND_POINTS: 'Ambos',
  };
  return labels[type] || 'N/A';
});

Vue.filter('roles', function (type) {
  switch (type) {
    case 'local_logistics_leader':
      return 'Líder de Logística Local';
    case 'national_logistics_leader':
      return 'Líder de Logística Nacional';
    case 'super_admin':
      return 'Super Administrador';
    case 'commercial':
      return 'Comercial';
  }
});
