/* eslint-disable camelcase */
const resource = '/api/services';

export default ($http) => ({
  calculateCost({
    coordinates,
    round_trip,
    city_id = 118,
    vehicle_type = 'CAR',
  }) {
    return $http.post(`${resource}/calculateCost`, {
      calculation_cost: {
        coordinates,
        city_id,
        round_trip,
        vehicle_type,
      },
    });
  },
  requestService({
    payment_method,
    round_trip,
    cost_details,
    client_type,
    modality,
    discount_price,
    service_type,
    directions,
    is_pidetudomi,
    parking_value,
    activation_date,
    insurance_value,
    coupon_code,
    description,
    nombre_destinatario,
    deliveryman_id,
    calculation_cost,
    telefono_destinatario,
  }) {
    return $http.post(`${resource}/request`, {
      client_type,
      modality,
      payment_method,
      round_trip,
      description,
      value: cost_details.consolidado,
      base: cost_details.base,
      discount_price,
      service_type,
      directions,
      is_pidetudomi,
      parking_value,
      insurance_value,
      activation_date,
      coupon_code,
      nombre_destinatario,
      telefono_destinatario,
      deliveryman_id,
      calculation_cost,
    });
  },
});
