import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store/store.js';
import middlewarePipeline from './middlewarePipeline';
import verifyStatus from './middleware/verify-status';
Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      // =============================================================================
      // MAIN LAYOUT ROUTES
      // =============================================================================
      path: '',
      component: () => import('../layouts/main/Main.vue'),

      children: [
        // =============================================================================
        // Socios Routes
        // =============================================================================
        {
          path: '/',
          name: 'inicio',
          redirect: '/solicitud',
        },
        {
          path: '/solicitud',
          name: 'solicitud',
          component: () => import('../views/Solicitud.vue'),
          meta: {
            middleware: [verifyStatus],
          },
        },
        {
          path: '/panel-de-control',
          name: 'panel-de-control',
          component: () => import('../views/PanelControl.vue'),
          meta: {
            pageTitle: 'Panel de control',
          },
        },
        {
          path: '/historial-de-servicios',
          name: 'historial-de-servicios',
          component: () => import('../views/HistorialServicios.vue'),
          meta: {
            pageTitle: 'Historial de servicios',
          },
        },

        {
          path: '/socios/logisticos',
          name: 'socios-logisticos',
          component: () =>
            import('../views/socios-logisticos/SociosLogisticos.vue'),
          meta: {
            pageTitle: 'Socios logísticos',
          },
        },

        {
          path: '/socios/logisticos/editar-socio/:socioId',
          name: 'socios-logisticos-editar-socio',
          component: () =>
            import('../views/socios-logisticos/editar-socio/EditarSocio.vue'),
          meta: {
            pageTitle: 'Detalle socio',
          },
        },

        {
          path: '/socios/saldo-socios',
          name: 'saldo-socios',
          component: () => import('../views/socios/SaldoSocios.vue'),
          meta: {
            pageTitle: 'Saldo socios',
          },
        },
        {
          path: '/socios/puntos-socios',
          name: 'puntos-socios',
          component: () => import('../views/socios/PuntosSocios.vue'),
          meta: {
            pageTitle: 'Puntos socios',
          },
        },
        {
          path: '/socios/saldo-en-canje',
          name: 'saldo-en-canje',
          component: () => import('../views/socios/SaldoEnCanje.vue'),
          meta: {
            pageTitle: 'Saldo en canje',
          },
          beforeEnter: (to, from, next) => {
            if (!router.app.$gates.hasPermission('show_exchange_balance')) {
              next('/');
            }
            next();
          },
        },

        {
          path: '/socios/tienda',
          name: 'socios-tienda',
          component: () => import('../views/socios/Tienda.vue'),
          meta: {
            pageTitle: 'Tienda',
          },
        },
        {
          path: '/socios/tienda/editar-producto/:productoId',
          name: 'socios-tienda-editar-producto',
          component: () =>
            import('../views/socios/editar-producto/EditarProducto.vue'),
          meta: {
            pageTitle: 'Detalle producto',
          },
        },
        {
          path: '/socios/cuentas-bancarias',
          name: 'socios-cuentas-bancarias',
          component: () =>
            import('../views/socios/cuentas-bancarias/CuentasBancarias.vue'),
          meta: {
            pageTitle: 'Cuentas bancarias',
          },
          beforeEnter: (to, from, next) => {
            if (
              !router.app.$gates.hasPermission('bank_accounts_administrator')
            ) {
              next('/');
            }
            next();
          },
        },

        // =============================================================================
        // Configuración Routes
        // =============================================================================

        {
          path: '/configuracion',
          name: 'configuracion',
          redirect: '/configuracion/empresa',
        },
        {
          path: '/configuracion/empresa',
          name: 'configuracion-empresa',
          component: () => import('../views/configuracion/Empresa.vue'),
          meta: {
            pageTitle: 'Empresa',
          },
          beforeEnter: (to, from, next) => {
            if (!router.app.$gates.hasPermission('show_set_up_company')) {
              next('/');
            }
            next();
          },
        },
        {
          path: '/configuracion/empresa/editar-empresa',
          name: 'configuracion-empresa-editar-empresa',
          component: () =>
            import('../views/configuracion/editar-empresa/EditarEmpresa.vue'),
          meta: {
            pageTitle: 'Editar Empresa',
          },
        },

        {
          path: '/configuracion/alarmas',
          name: 'configuracion-alarmas',
          component: () => import('../views/configuracion/Alarmas.vue'),
          meta: {
            pageTitle: 'Alarmas',
          },
          beforeEnter: (to, from, next) => {
            if (!router.app.$gates.hasPermission('edit_alarms')) {
              next('/');
            }
            next();
          },
        },

        // =============================================================================
        // Inventario Routes
        // =============================================================================

        {
          path: '/inventario',
          name: 'inventario',
          redirect: '/inventario/productos',
        },
        {
          path: '/inventario/productos',
          name: 'inventario-productos',
          component: () =>
            import('../views/inventario/productos/Productos.vue'),
          meta: {
            pageTitle: 'Inventario',
          },
          beforeEnter: (to, from, next) => {
            if (!router.app.$gates.hasPermission('show_inventory')) {
              next('/');
            }
            next();
          },
        },
        {
          path: '/inventario/productos/editar/:id',
          name: 'inventario-editar-producto',
          component: () =>
            import('../views/inventario/productos/FormularioProductos.vue'),
          props: { mode: 'edit' },
          meta: {
            pageTitle: 'Editar producto',
          },
          beforeEnter: (to, from, next) => {
            if (!router.app.$gates.hasPermission('edit_inventory')) {
              next('/');
            }
            next();
          },
        },
        {
          path: '/inventario/productos/crear',
          name: 'inventario-crear-producto',
          component: () =>
            import('../views/inventario/productos/FormularioProductos.vue'),
          props: { mode: 'create' },
          meta: {
            pageTitle: 'Crear producto',
          },
          beforeEnter: (to, from, next) => {
            if (!router.app.$gates.hasPermission('create_inventory')) {
              next('/');
            }
            next();
          },
        },
        {
          path: '/inventario/historial',
          name: 'inventario-historial',
          component: () =>
            import(
              '../views/inventario/historial-inventario/HistorialInventario.vue'
            ),
          meta: {
            pageTitle: 'Historial de inventario',
          },
          beforeEnter: (to, from, next) => {
            if (!router.app.$gates.hasPermission('show_inventory')) {
              next('/');
            }
            next();
          },
        },
        {
          path: '/inventario/ventas',
          name: 'inventario-ventas',
          component: () =>
            import('../views/inventario/historial-ventas/VentasInventario.vue'),
          meta: {
            pageTitle: 'Ventas de inventario',
          },
          beforeEnter: (to, from, next) => {
            if (!router.app.$gates.hasPermission('show_inventory')) {
              next('/');
            }
            next();
          },
        },

        // =============================================================================
        // Tarifas por áreas
        // =============================================================================
        {
          path: '/configuracion/tarifas-por-area',
          name: 'configuracion-tarifas-por-area',
          component: () => import('../views/configuracion/TarifasPorArea.vue'),
          meta: {
            pageTitle: 'Tarifas por área',
          },
          beforeEnter: (to, from, next) => {
            if (!router.app.$gates.hasPermission('edit_area_tariffs')) {
              next('/');
            }
            next();
          },
        },
        {
          path: '/configuracion/tarifas-por-area/editar/:id',
          name: 'configuracion-editar-tarifas-por-area',
          component: () =>
            import('../views/configuracion/FormularioTarifasPorArea.vue'),
          props: { mode: 'edit' },
          meta: {
            pageTitle: 'Editar tarifa por área',
          },
          beforeEnter: (to, from, next) => {
            if (!router.app.$gates.hasPermission('edit_area_tariffs')) {
              next('/');
            }
            next();
          },
        },
        {
          path: '/configuracion/tarifas-por-area/crear',
          name: 'configuracion-crear-tarifas-por-area',
          component: () =>
            import('../views/configuracion/FormularioTarifasPorArea.vue'),
          props: { mode: 'create' },
          meta: {
            pageTitle: 'Crear tarifa por área',
          },
          beforeEnter: (to, from, next) => {
            if (!router.app.$gates.hasPermission('edit_area_tariffs')) {
              next('/');
            }
            next();
          },
        },

        // =============================================================================
        // Negociacion
        // =============================================================================
        {
          path: '/configuracion/negociaciones',
          name: 'configuracion-negociaciones',
          component: () => import('../views/configuracion/Negociaciones.vue'),
          meta: {
            pageTitle: 'Negociaciones',
          },
          beforeEnter: (to, from, next) => {
            if (!router.app.$gates.hasPermission('edit_negotiations')) {
              next('/');
            }
            next();
          },
        },
        {
          path: '/configuracion/negociaciones/editar/:id',
          name: 'configuracion-editar-negociacion',
          component: () =>
            import('../views/configuracion/FormularioNegociacion.vue'),
          props: { mode: 'edit' },
          meta: {
            pageTitle: 'Editar negociación',
          },
          beforeEnter: (to, from, next) => {
            if (!router.app.$gates.hasPermission('edit_negotiations')) {
              next('/');
            }
            next();
          },
        },
        {
          path: '/configuracion/negociaciones/crear',
          name: 'configuracion-crear-negociacion',
          component: () =>
            import('../views/configuracion/FormularioNegociacion.vue'),
          props: { mode: 'create' },
          meta: {
            pageTitle: 'Crear negociación',
          },
          beforeEnter: (to, from, next) => {
            if (!router.app.$gates.hasPermission('edit_negotiations')) {
              next('/');
            }
            next();
          },
        },

        // =============================================================================
        // Alarma
        // =============================================================================
        {
          path: '/configuracion/alarmas/editar-alarma/:id',
          name: 'configuracion-editar-alarma',
          component: () =>
            import('../views/configuracion/FormularioAlarma.vue'),
          props: { mode: 'edit' },
          meta: {
            pageTitle: 'Editar alarma',
          },
        },
        {
          path: '/configuracion/alarmas/crear-alarma',
          name: 'configuracion-crear-alarma',
          component: () =>
            import('../views/configuracion/FormularioAlarma.vue'),
          props: { mode: 'create' },
          meta: {
            pageTitle: 'Crear alarma',
          },
        },

        // Se elimino la ruta por que no se tiene previsto.
        /*{
          path: '/configuracion/capacitaciones',
          name: 'configuracion-capacitaciones',
          component: () => import('../views/configuracion/Capacitaciones.vue'),
          meta: {
            pageTitle: 'Capacitaciones',
          },
        },*/
        {
          path: '/configuracion/capacitaciones/crear-capacitacion',
          name: 'configuracion-crear-capacitacion',
          component: () =>
            import(
              '../views/configuracion/crear-capacitacion/CrearCapacitacion.vue'
            ),
          meta: {
            pageTitle: 'Crear capacitación',
          },
        },
        // =============================================================================
        // Finanzas Routes
        // =============================================================================
        {
          path: '/finanzas',
          name: 'finanzas',
          redirect: '/finanzas/dashboard',
          meta: {},
        },
        {
          path: '/finanzas/dashboard',
          name: 'finanzas-dashboard',
          component: () => import('../views/finanzas/Dashboard.vue'),
          meta: {
            pageTitle: 'Dashboard',
          },
        },
        // {
        //   path: '/finanzas/movimientos-caja',
        //   name: 'finanzas-movimientos-caja',
        //   component: () => import('../views/finanzas/MovimientosCaja.vue'),
        //   meta: {
        //     pageTitle: 'Movimientos de caja',
        //   },
        // },
        {
          path: '/finanzas/movimientos-caja/:accountId',
          name: 'finanzas-movimientos-caja',
          component: () => import('../views/finanzas/MovimientosCaja.vue'),
          meta: {
            pageTitle: 'Movimientos de caja',
          },
        },
        {
          path: '/finanzas/movimientos-caja/:accountId/agregar-nota-contable',
          name: 'finanzas-movimientos-caja-agregar-nota-contable',
          component: () =>
            import('../views/finanzas/movimientos-caja/AgregarNota.vue'),
          meta: {
            pageTitle: 'Agregar nota contable',
          },
          beforeEnter: (to, from, next) => {
            if (!router.app.$gates.hasPermission('add_account_moves')) {
              next('/');
            }
            next();
          },
        },
        {
          path: '/finanzas/transferencias',
          name: 'finanzas-transferencias',
          component: () => import('../views/finanzas/Transferencias.vue'),
          meta: {
            pageTitle: 'Crear transferencia',
          },
          beforeEnter: (to, from, next) => {
            if (
              !router.app.$gates.hasPermission('transfers_account_to_account')
            ) {
              next('/finanzas/dashboard');
            }
            next();
          },
        },
        {
          path: '/finanzas/cajas',
          name: 'finanzas-cajas',
          component: () => import('../views/finanzas/Cajas.vue'),
          meta: {
            pageTitle: 'Cajas',
          },
          beforeEnter: (to, from, next) => {
            if (!router.app.$gates.hasPermission('show_accounts')) {
              next('/');
            }
            next();
          },
        },
        {
          path: '/finanzas/cajas/crear-caja',
          name: 'finanzas-cajas-crear-caja',
          component: () => import('../views/finanzas/cajas/CrearCaja.vue'),
          meta: {
            pageTitle: 'Crear Caja',
          },
        },
        {
          path: '/finanzas/cajas/eliminar-caja',
          name: 'finanzas-cajas-eliminar-caja',
          component: () => import('../views/finanzas/cajas/EliminarCaja.vue'),
          meta: {
            pageTitle: 'Eliminar Caja',
          },
        },
        {
          path: '/finanzas/facturacion',
          name: 'finanzas-facturacion',
          component: () =>
            import('../views/finanzas/facturacion/Facturacion.vue'),
          meta: {
            pageTitle: 'Facturación',
          },
          beforeEnter: (to, from, next) => {
            if (!router.app.$gates.hasPermission('show_invoices')) {
              next('/');
            }
            next();
          },
        },
        {
          path: '/finanzas/facturacion/crear',
          name: 'finanzas-facturacion-crear',
          component: () =>
            import('../views/finanzas/facturacion/CrearFactura.vue'),
          meta: {
            pageTitle: 'Crear factura',
          },
          beforeEnter: (to, from, next) => {
            if (!router.app.$gates.hasPermission('edit_invoices')) {
              next('/');
            }
            next();
          },
        },
        {
          path: '/finanzas/solicitudes-retiro',
          name: 'finanzas-solicitudes-retiro',
          component: () =>
            import(
              '../views/finanzas/solicitudes-retiro/SolicitudesRetiro.vue'
            ),
          meta: {
            pageTitle: 'Solicitudes de retiro',
          },
          beforeEnter: (to, from, next) => {
            if (
              !router.app.$gates.hasPermission('withdraw_request_administrator')
            ) {
              next('/');
            }
            next();
          },
        },
        {
          path: '/finanzas/historial-solicitudes-retiro',
          name: 'finanzas-historial-solicitudes-retiro',
          component: () =>
            import(
              '../views/finanzas/historial-solicitudes-retiro/HistorialSolicitudesRetiro.vue'
            ),
          meta: {
            pageTitle: 'Historial Solicitudes de retiro',
          },
          beforeEnter: (to, from, next) => {
            if (
              !router.app.$gates.hasPermission('withdraw_request_administrator')
            ) {
              next('/');
            }
            next();
          },
        },
        {
          // =============================================================================
          // Calendario Routes
          // =============================================================================
          path: '/calendario',
          name: 'calendario',
          component: () => import('../views/Calendario.vue'),
          meta: {
            pageTitle: 'Calendario',
          },
        },

        {
          // =============================================================================
          // Terceros Routes
          // =============================================================================
          path: '/terceros/franquicias',
          name: 'terceros-franquicias',
          beforeEnter: (to, from, next) => {
            if (!router.app.$gates.hasPermission('edit_franchises')) {
              next('/');
            }
            next();
          },
          component: () =>
            import('../views/terceros/franquicias/Franquicias.vue'),

          meta: {
            pageTitle: 'Franquicias',
          },
        },
        {
          path: '/terceros/franquicias/editar-franquicia/:franquiciaId',
          name: 'terceros-franquicias-editar-franquicia',
          component: () =>
            import(
              '../views/terceros/franquicias/editar-franquicia/EditarFranquicia.vue'
            ),
          meta: {
            pageTitle: 'Detalle franquicia',
          },
        },
        {
          path: '/terceros/admin-franquicias',
          name: 'terceros-admin-franquicias',
          component: () =>
            import('../views/terceros/franquicias/admin/Franquicias.vue'),
          meta: {
            pageTitle: 'Admin franquicias',
          },
          beforeEnter: (to, from, next) => {
            if (!router.app.$gates.hasPermission('show_admin_franchises')) {
              next('/');
            }
            next();
          },
        },
        {
          path: '/terceros/franquicias/admin/editar-franquicia/:franquiciaId',
          name: 'terceros-franquicias-admin-editar-franquicia',
          component: () =>
            import(
              '../views/terceros/franquicias/admin/editar-franquicia/EditarFranquicia.vue'
            ),
          meta: {
            pageTitle: 'Detalle Admin Franquicias',
          },
        },
        {
          path: '/terceros/barrios',
          name: 'terceros-barrios',
          component: () => import('../views/terceros/Barrios.vue'),
          meta: {
            pageTitle: 'Barrios',
          },
        },
        {
          path: '/terceros/barrios/crear-barrio',
          name: 'terceros-crear-barrio',
          props: { mode: 'create' },
          component: () =>
            import('../views/terceros/barrios/FormularioBarrio.vue'),
          meta: {
            pageTitle: 'Crear Barrio',
          },
        },
        {
          path: '/terceros/barrios/editar-barrio/:id',
          name: 'terceros-editar-barrio',
          component: () =>
            import('../views/terceros/barrios/FormularioBarrio.vue'),
          props: { mode: 'edit' },
          meta: {
            pageTitle: 'Editar barrio',
          },
        },

        {
          // =============================================================================
          // Comercial Routes
          // =============================================================================
          path: '/comercial/negociaciones',
          name: 'comercial-negociaciones',
          component: () => import('../views/comercial/Negociaciones.vue'),
          meta: {
            pageTitle: 'Negociaciones',
          },
        },
        // {
        //   path: '/comercial/negociaciones/editar-negociacion/:negociacionId',
        //   name: 'comercial-negociaciones-editar-negociacion',
        //   component: () =>
        //     import(
        //       '../views/comercial/negociaciones/editar-negociacion/EditarNegociacion.vue'
        //     ),
        //   meta: {
        //     pageTitle: 'Detalle negociación',
        //   },
        // },

        {
          // =============================================================================
          // Planta Routes
          // =============================================================================
          path: '/plantas',
          name: 'plantas',
          component: () => import('../views/plantas/Plantas.vue'),
          meta: {
            pageTitle: 'Listado de Plantas',
          },
          beforeEnter: (to, from, next) => {
            if (!router.app.$gates.hasPermission('show_plants')) {
              next('/');
            }
            next();
          },
        },
        // {
        //   path: '/plantas/detalle-planta',
        //   name: 'plantas-detalle-planta',
        //   component: () => import('../views/plantas/DetallePlanta.vue'),
        //   meta: {
        //     pageTitle: 'Detalle planta',
        //   },
        // },
        {
          path: '/plantas/agregar',
          name: 'add-plant',
          component: () => import('../views/plantas/AddPlant.vue'),
          meta: {
            pageTitle: 'Agregar Nueva Planta',
          },
        },
        {
          path: '/plantas/:id/actualizar',
          name: 'update-plant',
          component: () => import('../views/plantas/UpdatePlant.vue'),
          meta: {
            pageTitle: 'Actualizar Planta',
          },
        },
        {
          path: '/plantas/delegacion',
          name: 'delegacion-plantas',
          component: () => import('../views/plantas/Delegacion.vue'),
          meta: {
            pageTitle: 'Delegación de Plantas',
          },
        },
        {
          path: '/plantas/admin',
          name: 'admin-plantas',
          component: () => import('../views/plantas/AdminPlantas.vue'),
          meta: {
            rule: 'admin',
            pageTitle: 'Admin plantas',
          },
          beforeEnter: (to, from, next) => {
            if (!router.app.$gates.hasPermission('show_admin_plants')) {
              next('/');
            }
            next();
          },
        },

        {
          path: '/mapa',
          name: 'mapa',
          component: () => import('../views/Mapa.vue'),
          meta: {
            pageTitle: 'Mapa',
          },
        },
      ],
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: '',
      component: () => import('@/layouts/full-page/FullPage.vue'),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {
          path: '/iniciar-sesion',
          name: 'page-login',
          component: () => import('@/views/iniciar-sesion/Login.vue'),
          meta: {
            public: true, // Allow access to even if not logged in
            onlyWhenLoggedOut: true,
          },
        },
        {
          path: '/registrarse',
          name: 'page-register',
          component: () => import('@/views/registrarse/Register.vue'),
          meta: {
            public: true, // Allow access to even if not logged in
            onlyWhenLoggedOut: true,
          },
        },
        {
          path: '/pages/error-404',
          name: 'page-error-404',
          component: () => import('@/views/pages/Error404.vue'),
          meta: {
            public: true,
          },
        },
        {
          path: '/pages/not-authorized',
          name: 'page-not-authorized',
          component: () => import('@/views/pages/NotAuthorized.vue'),
          meta: {},
        },
        {
          path: '/pages/completar-datos',
          name: 'page-completar-datos',
          component: () => import('@/views/pages/CompletarDatos.vue'),
          meta: {},
        },
      ],
    },
    // Redirect to 404 page, if no match found
    {
      path: '*',
      redirect: '/pages/error-404',
      public: true,
    },
  ],
});

// Remove initial loading
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg');
  if (appLoading) {
    appLoading.style.display = 'none';
  }
});

router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
    return next();
  }

  const middleware = to.meta.middleware;

  const context = {
    to,
    from,
    next,
    store,
  };

  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1),
    nextVue: context.next,
  });
});

// Auth middleware
router.beforeEach((to, from, next) => {
  const isPublic = to.matched.some((record) => record.meta.public);
  const onlyWhenLoggedOut = to.matched.some(
    (record) => record.meta.onlyWhenLoggedOut
  );

  const loggedIn = !!store.state.auth.isUserLoggedIn();

  if (!isPublic && !loggedIn) {
    return next({
      path: '/iniciar-sesion',
      query: { redirect: to.fullPath }, // Store the full path to redirect the user to after login
    });
  }

  // Do not allow user to visit login page or register page if they are logged in
  if (loggedIn && onlyWhenLoggedOut) {
    return next('/');
  }

  next();
});

export default router;
