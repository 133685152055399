export default function verifyStatus({ next, store, to }) {
  const user = store.state.AppActiveUser;
  const notAuthPath = '/pages/not-authorized';
  if (user) {
    if (!user.is_active) {
      return next({
        path: notAuthPath,
        query: { redirect: to.fullPath },
      });
    }
  }

  return next();
}
