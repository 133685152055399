import gql from 'graphql-tag';
export const ENUMS = gql`
  enum TransactionType {
    RECHARGE
    DISCHARGE
  }
  enum Backend {
    MANAGER
    LITE
    APP_WEB
    FRANCHISE
    DELIVERYMEN
  }
`;
export const UPDATE_SERVICE = gql`
  mutation updateService($input: UpdateServiceInput) {
    updateService(input: $input) {
      id
      payment_method
      round_trip
      state {
        id
        name
        code
        is_done
      }
      directions {
        address
        description
        coordinates {
          lat
          lng
        }
      }
      destinatary {
        id
        name
        phone
        street
      }
      stateHistorical {
        id
        created_at
        state {
          id
          name
          service_type {
            id
            name
          }
          is_done
          code
        }
      }
      deliveryman {
        id
        address
        email
        document_number
        user {
          id
          first_name
          phone
          last_name
        }
      }
      description
      value
      parking_value
      insurance_value
      created_at
      updated_at
    }
  }
`;
export const INPUTS = gql`
  input UserRefInput {
    user_id: Int
    backend: Backend
  }
`;
export const UPDATE_ADMIN_FRANCHISE = gql`
  mutation ($input: UpdateUserFranchiseInput) {
    updateAdminFranchises(input: $input) {
      id
      first_name
      last_name
      phone
      email
    }
  }
`;
export const CREATE_ACCOUNT_MOVES = gql`
  mutation ($input: AccountMoveInput!) {
    createAccountMove(input: $input) {
      transaction {
        id
        amount
        backend
        description
        type
        user_id
        deliveryman {
          first_name
          last_name
        }
        service {
          id
        }
        created_at
      }
      accountMove {
        id
        amount
        transaction_type
        account_type
        supplier
        nit
        description
        date
      }
    }
  }
`;

export const CREATE_TRANSFER_MOVES = gql`
  mutation ($input: TransferAccountToAccount!) {
    transferAccountToAccount(input: $input) {
      transaction {
        id
      }
      accountMove {
        id
      }
    }
  }
`;

export const UPDATE_FRANCHISE = gql`
  mutation updateFranchise($input: UpdateFranchiseInput!) {
    updateFranchise(input: $input) {
      id
      nit
      commerce_type
      address
      name
      phone
      contact_name
      attention_schedule
      delivery_media
    }
  }
`;

export const CREATE_TARIFF = gql`
  mutation createTariff($input: CreateTariffInput!) {
    createTariff(input: $input) {
      id
      city {
        name
      }
      base_price
      base_kms
      base_minutes
      additional_km_price
      additional_minute_price
      round_trip_percent
      stops_price
      vehicle_type {
        id
        name
        alias
      }
    }
  }
`;

export const DELETE_TARIFF = gql`
  mutation deleteTariff($id: ID!) {
    deleteTariff(id: $id) {
      id
    }
  }
`;

export const CREATE_PLANT_DELEGATION = gql`
  mutation createPlantDelegation($input: PlantDelegationInput!) {
    createPlantDelegation(input: $input) {
      id
      plant {
        id
        name
        franchise {
          id
          name
          phone
        }
      }
      deliveryman {
        id
        user {
          id
          wallet {
            id
            amount
          }
          phone
          first_name
          last_name
        }
        delegations {
          id
          monday
          tuesday
          wednesday
          thursday
          friday
          saturday
          sunday
          environment
        }
      }
      monday
      tuesday
      wednesday
      thursday
      friday
      saturday
      sunday
      environment
    }
  }
`;

export const CREATE_PLANT = gql`
  mutation createPlant($input: plantInput!) {
    createPlant(input: $input) {
      id
      active
      name
      description
      franchise {
        id
        name
      }
      availability {
        id
        monday_seats
        tuesday_seats
        wednesday_seats
        thursday_seats
        friday_seats
        saturday_seats
        sunday_seats
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
        monday_value
        tuesday_value
        wednesday_value
        thursday_value
        friday_value
        saturday_value
        sunday_value
      }
      start_hour
      end_hour
      created_at
      updated_at
    }
  }
`;

export const UPDATE_PLANT = gql`
  mutation ($input: plantInput) {
    updatePlant(input: $input) {
      id
      active
      name
      description
      franchise {
        id
        name
      }
      availability {
        id
        monday_seats
        tuesday_seats
        wednesday_seats
        thursday_seats
        friday_seats
        saturday_seats
        sunday_seats
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
        monday_value
        tuesday_value
        wednesday_value
        thursday_value
        friday_value
        saturday_value
        sunday_value
      }
      start_hour
      end_hour
      created_at
      updated_at
    }
  }
`;
export const DELETE_PLANT = gql`
  mutation deletePlant($id: ID!) {
    deletePlant(id: $id) {
      id
      active
      name
      description
      franchise {
        id
        name
      }
      availability {
        id
        monday_seats
        tuesday_seats
        wednesday_seats
        thursday_seats
        friday_seats
        saturday_seats
        sunday_seats
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
        monday_value
        tuesday_value
        wednesday_value
        thursday_value
        friday_value
        saturday_value
        sunday_value
      }
      start_hour
      end_hour
      created_at
      updated_at
    }
  }
`;

export const REQUEST_SERVICE = gql`
  mutation ($input: requestServiceInput!) {
    requestService(input: $input) {
      id
      payment_method
      round_trip
      state {
        id
        name
        code
      }
      directions {
        address
        description
        coordinates {
          lat
          lng
        }
      }
      sender {
        id
        name
        phone
        street
        coordinates {
          lat
          lng
        }
      }
      destinatary {
        id
        name
        phone
        street
      }
      stateHistorical {
        id
        created_at
        state {
          id
          name
          service_type {
            id
            name
          }
          is_done
          code
        }
      }
      deliveryman {
        id
        address
        email
        document_number
        user {
          id
          first_name
          phone
          last_name
        }
      }
      description
      value
      parking_value
      insurance_value
      created_at
    }
  }
`;

export const MULTIPLE_REQUEST_SERVICE = gql`
  mutation ($input: multipleRequestServiceInput!) {
    multipleRequestService(input: $input) {
      id
    }
  }
`;

export const REQUEST_PROGRAMMED_SERVICE = gql`
  mutation ($input: requestServiceInput!) {
    requestProgrammedService(input: $input) {
      id
      payment_method
      round_trip
      franchise {
        id
        name
      }
      directions {
        address
        description
        coordinates {
          lat
          lng
        }
      }
      sender {
        id
        name
        phone
        street
        coordinates {
          lat
          lng
        }
      }
      destinatary {
        id
        name
        phone
        street
      }
      color
      description
      value
      parking_value
      insurance_value
      created_at
      programmed_at
    }
  }
`;

export const CANCEL_SERVICE = gql`
  mutation ($id: ID!, $specialKey: String!) {
    cancelService(id: $id, specialKey: $specialKey) {
      id
      payment_method
      round_trip
      state {
        id
        name
        code
        is_done
        alarms {
          id
          max_time
          color
        }
      }
      directions {
        address
        description
        coordinates {
          lat
          lng
        }
      }
      destinatary {
        id
        name
        phone
        street
      }
      stateHistorical {
        id
        created_at
        state {
          id
          name
          alarms {
            id
            max_time
            color
          }
          service_type {
            id
            name
          }
          is_done
          code
        }
      }
      deliveryman {
        id
        address
        email
        document_number
        user {
          id
          first_name
          phone
          last_name
        }
      }
      franchise {
        id
        name
      }
      description
      value
      parking_value
      insurance_value
      created_at
      updated_at
    }
  }
`;
export const TOGGLE_ACTIVE_PLANT = gql`
  mutation ($active: Boolean!, $ids: [ID!]!) {
    toggleActivePlant(active: $active, ids: $ids) {
      id
      active
    }
  }
`;
export const NOTIFY_PLANT_DELEGATIONS = gql`
  mutation ($ids: [ID!]!) {
    notify_plant_delegations(ids: $ids) {
      id
    }
  }
`;
export const LAUNCH_PLANT_DELEGATIONS = gql`
  mutation ($ids: [ID!]!) {
    launchPlannedPlantDelegations(ids: $ids) {
      id
      delegations {
        id
        deliveryman {
          id
          user {
            id
            wallet {
              id
              amount
            }
            phone
            first_name
            last_name
          }
        }
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
        environment
      }
    }
  }
`;
export const RESET_PLANT_DELEGATIONS = gql`
  mutation ($ids: [ID!]!) {
    resetPlantDelegations(ids: $ids) {
      id
      delegations {
        id
        deliveryman {
          id
          user {
            id
            wallet {
              id
              amount
            }
            phone
            first_name
            last_name
          }
        }
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
        environment
      }
    }
  }
`;
export const RECHARGE_DELIVERYMAN = gql`
  mutation (
    $user: UserRefInput!
    $amount: Int!
    $type: TransactionType!
    $account_id: Int!
  ) {
    makeTransaction(
      user: $user
      amount: $amount
      type: $type
      account_id: $account_id
    ) {
      amount
      user_id
      backend
      deliveryman {
        id
        wallet {
          amount
        }
      }
    }
  }
`;
export const UPDATE_COMPANY = gql`
  mutation (
    $id: ID!
    $name: String!
    $user_legal: String!
    $email: String!
    $code: String!
    $nit: String!
    $phone_first: String!
    $phone_secondary: String
    $address: String!
    $city_id: Int
  ) {
    updateCompany(
      id: $id
      name: $name
      user_legal: $user_legal
      email: $email
      code: $code
      nit: $nit
      phone_first: $phone_first
      phone_secondary: $phone_secondary
      address: $address
      city_id: $city_id
    ) {
      id
      name
      user_legal
      email
      code
      nit
      phone_first
      phone_secondary
      address
      city {
        id
        name
        department {
          id
          name
        }
      }
    }
  }
`;

export const UPDATE_SPECIAL_KEY = gql`
  mutation ($current_special_key: String!, $new_special_key: String!) {
    updateSpecialKey(
      current_special_key: $current_special_key
      new_special_key: $new_special_key
    ) {
      id
    }
  }
`;

export const UPDATE_STATE_SERVICE = gql`
  mutation ($id: ID!, $state_id: ID!) {
    updateStatusService(id: $id, state_id: $state_id) {
      id
      payment_method
      round_trip
      state {
        id
        name
        code
      }
      directions {
        address
        description
        coordinates {
          lat
          lng
        }
      }
      destinatary {
        id
        name
        phone
        street
      }
      stateHistorical {
        id
        created_at
        state {
          id
          name
          service_type {
            id
            name
          }
          is_done
          code
        }
      }
      deliveryman {
        id
        address
        email
        document_number
        user {
          id
          first_name
          phone
          last_name
        }
      }
      description
      value
      parking_value
      insurance_value
      created_at
      updated_at
    }
  }
`;
export const UPDATE_DELIVERYMAN = gql`
  mutation ($input: UpdateDeliverymanInput!) {
    updateDeliveryman(input: $input) {
      id
      code
      agreement_number
      address
      utility
      email
      document_number
      observation
      city {
        id
        name
        department {
          id
          name
        }
      }
      eps
      arl
      pension_fund
      profile_picture_url
      vehicles {
        id
        plate
        annual_revision
        soat
        vehicleType {
          id
          name
          alias
        }
      }
      document_type {
        id
        document_name
        document_shortname
      }
      user {
        id
        first_name
        last_name
      }
    }
  }
`;
export const UPDATE_DELIVERYMAN_STATUS = gql`
  mutation ($id: ID!, $status: DeliverymanStatus!, $observation: String) {
    updateDeliverymanStatus(
      id: $id
      status: $status
      observation: $observation
    ) {
      id
      address
      user {
        id
        first_name
        last_name
        phone
        wallet {
          amount
        }
      }
      historyDeliverymanStatuses {
        id
        status
        observation
        created_at
      }
      document_number
      status
      data_status
      level
    }
  }
`;

export const UPDATE_PLANT_DELEGATION = gql`
  mutation ($input: PlantDelegationInput!) {
    updatePlantDelegation(input: $input) {
      id
      plant {
        id
        name
        franchise {
          id
          name
          phone
        }
      }
      deliveryman {
        id
        user {
          id
          wallet {
            id
            amount
          }
          phone
          first_name
          last_name
        }
        delegations {
          id
          monday
          tuesday
          wednesday
          thursday
          friday
          saturday
          sunday
          environment
        }
      }
      monday
      tuesday
      wednesday
      thursday
      friday
      saturday
      sunday
      environment
    }
  }
`;

export const DELETE_PLANT_DELEGATION = gql`
  mutation ($id: ID!) {
    deletePlantDelegation(id: $id) {
      id
      plant {
        id
        name
        franchise {
          id
          name
          phone
        }
      }
      deliveryman {
        id
        user {
          id
          wallet {
            id
            amount
          }
          phone
          first_name
          last_name
        }
        delegations {
          id
          monday
          tuesday
          wednesday
          thursday
          friday
          saturday
          sunday
          environment
        }
      }
      monday
      tuesday
      wednesday
      thursday
      friday
      saturday
      sunday
      environment
    }
  }
`;

export const UPDATE_PROGRAMMED_SERVICE = gql`
  mutation (
    $id: ID!
    $programmed_at: DateTime!
    $color: String!
    $deliveryman_id: ID!
  ) {
    updateProgrammedService(
      id: $id
      programmed_at: $programmed_at
      color: $color
      deliveryman_id: $deliveryman_id
    ) {
      id
      payment_method
      description
      color
      deliveryman {
        id
      }
      franchise {
        id
        name
      }
      sender {
        name
        phone
        street
        coordinates {
          lat
          lng
        }
      }
      destinatary {
        id
        name
        phone
        street
      }

      directions {
        address
        description
        coordinates {
          lat
          lng
        }
      }

      programmed_at
    }
  }
`;

export const UPDATE_ALARM = gql`
  mutation updateAlarm($input: StateAlarmInput!) {
    updateAlarm(input: $input) {
      id
      name
      max_time
      color
      state {
        id
        name
        code
      }
    }
  }
`;

export const CREATE_ALARM = gql`
  mutation createAlarm($input: StateAlarmInput!) {
    createAlarm(input: $input) {
      id
      name
      max_time
      color
      state {
        id
        name
        code
      }
    }
  }
`;

export const DELETE_ALARM = gql`
  mutation deleteAlarm($id: ID!) {
    deleteAlarm(id: $id) {
      id
    }
  }
`;

export const UPDATE_DELIVERYMAN_LEVEL = gql`
  mutation updateDeliverymanLevel(
    $id: ID!
    $level: Level!
    $observation: String
  ) {
    updateDeliverymanLevel(id: $id, level: $level, observation: $observation) {
      level
    }
  }
`;

export const UPDATE_DATA_STATUS = gql`
  mutation updateDataStatus(
    $id: ID!
    $data_status: DeliverymanDataStatus!
    $observation: String
  ) {
    updateDataStatus(
      id: $id
      data_status: $data_status
      observation: $observation
    ) {
      data_status
    }
  }
`;

export const SEND_PUSH_NOTIFICATION = gql`
  mutation sendPushNotification($title: String!, $body: String!) {
    sendPushNotification(title: $title, body: $body) {
      message
      status
    }
  }
`;

export const SYNC_DELEGATIONS = gql`
  mutation syncDelegations($ids: [ID!]!) {
    syncDelegations(ids: $ids) {
      message
      status
      delegations {
        id
      }
    }
  }
`;

export const UPDATE_USER_COMPANY = gql`
  mutation updateUserCompany($company_id: ID!) {
    updateUserCompany(company_id: $company_id) {
      id
      company {
        id
        name
      }
    }
  }
`;

export const UPDATE_CREATE_NEIGHBORHOOD = gql`
  mutation ($id: ID, $name: String!, $city_id: ID!, $coordinates: [Float!]!) {
    updateOrCreateNeighborhood(
      id: $id
      name: $name
      coordinates: $coordinates
      city_id: $city_id
    ) {
      id
      name
      coordinates
      city {
        id
        name
      }
    }
  }
`;

export const DELETE_NEIGHBORHOOD = gql`
  mutation deleteNeighborhood($id: ID!) {
    deleteNeighborhood(id: $id) {
      id
    }
  }
`;

export const MAKE_TRANSACTION_POINTS = gql`
  mutation ($user_id: Int!, $amount: Int!, $type: TransactionPointType!) {
    makeTransactionPoints(user_id: $user_id, amount: $amount, type: $type) {
      id
      description
      amount
      userDeliveryman {
        id
        first_name
        last_name
      }
      date
    }
  }
`;

export const CREATE_NEGOTIATION = gql`
  mutation createNegotiation($input: NegotiationInput!) {
    createNegotiation(input: $input) {
      id
      name
      company {
        name
      }
    }
  }
`;

export const UPDATE_NEGOTIATION = gql`
  mutation updateNegotiation($input: NegotiationInput!) {
    updateNegotiation(input: $input) {
      id
      name
      company {
        name
      }
    }
  }
`;

export const CREATE_AREA_TARIFF = gql`
  mutation createAreaTariff($input: AreaTariffInput!) {
    createAreaTariff(input: $input) {
      id
      name
      price
      city {
        id
        name
      }
      round_trip_percent
      vehicleType {
        alias
      }
      negotiation {
        id
        name
      }
    }
  }
`;

export const UPDATE_AREA_TARIFF = gql`
  mutation updateAreaTariff($input: AreaTariffInput!) {
    updateAreaTariff(input: $input) {
      id
      name
      price
      city {
        id
        name
      }
      round_trip_percent
      vehicleType {
        alias
      }
      negotiation {
        id
        name
      }
    }
  }
`;

export const DELETE_AREA_TARIFF = gql`
  mutation deleteAreaTariff($id: ID!) {
    deleteAreaTariff(id: $id) {
      id
    }
  }
`;

export const DELETE_NEGOTIATION = gql`
  mutation deleteNegotiation($id: ID!) {
    deleteNegotiation(id: $id) {
      id
    }
  }
`;

export const CREATE_INVENTORY = gql`
  mutation createInventory($input: InventoryInput!) {
    createInventory(input: $input) {
      id
      name
    }
  }
`;

export const UPDATE_INVENTORY = gql`
  mutation updateInventory($input: UpdateInventoryInput!) {
    updateInventory(input: $input) {
      id
      name
    }
  }
`;

export const DELETE_INVENTORY = gql`
  mutation deleteInventory($id: ID!) {
    deleteInventory(id: $id) {
      id
    }
  }
`;

export const UPDATE_INVENTORY_PRICE = gql`
  mutation updateInventoryPrice($input: InventoryPriceInput!) {
    updateInventoryPrice(input: $input) {
      id
      name
    }
  }
`;

export const UPDATE_INVENTORY_STOCK = gql`
  mutation updateInventoryStock($input: InventoryStockInput!) {
    updateInventoryStock(input: $input) {
      id
      name
    }
  }
`;

export const CREATE_INVENTORY_SALE = gql`
  mutation createInventorySale($input: InventorySaleInput!) {
    createInventorySale(input: $input) {
      id
    }
  }
`;

export const REMOVE_DELIVERYMAN_IMAGE = gql`
  mutation removeDeliverymanImage($id: ID!, $image_field_to_remove: String!) {
    removeDeliverymanImage(
      id: $id
      image_field_to_remove: $image_field_to_remove
    ) {
      id
    }
  }
`;

export const UPLOAD_DELIVERYMAN_IMAGE = gql`
  mutation uploadDeliverymanImage(
    $id: ID!
    $image_field_to_update: String!
    $image: String!
  ) {
    uploadDeliverymanImage(
      id: $id
      image_field_to_update: $image_field_to_update
      image: $image
    ) {
      id
      profile_picture_url
      drivers_license_picture_url
      identification_document_picture_url
      property_card_picture_url
    }
  }
`;

export const REMOVE_VEHICLE_IMAGE = gql`
  mutation removeVehicleImage($id: ID!, $image_field_to_remove: String!) {
    removeVehicleImage(id: $id, image_field_to_remove: $image_field_to_remove) {
      id
    }
  }
`;

export const UPLOAD_VEHICLE_IMAGE = gql`
  mutation uploadVehicleImage(
    $id: ID!
    $image_field_to_update: String!
    $image: String!
  ) {
    uploadVehicleImage(
      id: $id
      image_field_to_update: $image_field_to_update
      image: $image
    ) {
      id
      picture_url
      plate_picture_url
    }
  }
`;

export const UPDATE_BANK_ACCOUNT_STATUS = gql`
  mutation updateStatus($input: BankAccountInput!) {
    updateStatus(input: $input) {
      status
    }
  }
`;

export const UPDATE_WITHDRAW_REQUEST_STATUS = gql`
  mutation updateWithdrawRequestStatus(
    $input: UpdateWithdrawRequestStatusInput!
  ) {
    updateWithdrawRequestStatus(input: $input) {
      status
    }
  }
`;

export const UPDATE_WITHDRAW_REQUEST_IMAGE = gql`
  mutation updateWithdrawRequestImage(
    $input: UpdateWithdrawRequestImageInput!
  ) {
    updateWithdrawRequestImage(input: $input) {
      image
    }
  }
`;

export const UPDATE_INVOICE_IMAGE = gql`
  mutation updateInvoiceImage($input: UpdateInvoiceImageInput!) {
    updateInvoiceImage(input: $input) {
      image
    }
  }
`;

export const CREATE_INVOICE = gql`
  mutation createInvoice($input: CreateInvoiceInput!) {
    createInvoice(input: $input) {
      id
    }
  }
`;

export const PAY_INVOICE = gql`
  mutation payInvoice($input: PayInvoiceInput!) {
    payInvoice(input: $input) {
      id
    }
  }
`;

export const CANCEL_INVOICE = gql`
  mutation cancelInvoice($id: ID!) {
    cancelInvoice(id: $id) {
      id
    }
  }
`;
