export default {
  SET_DEPARTMENTS(state, data) {
    state.departments = data;
  },
  SET_CITIES(state, data) {
    state.cities = data;
  },
  SET_COST_DETAILS(state, data) {
    state.costDetails = data;
  },
};
