import {
  REQUEST_SERVICE,
  REQUEST_PROGRAMMED_SERVICE,
} from '@/graphql/mutations';
import {
  CALCULATE_COST,
  CURRENT_SERVICES,
  PROGRAMMED_SERVICES,
} from '@/graphql/queries';
import apolloClient from '@/plugins/vue-apollo';

/* eslint-disable no-empty */
export default {
  async requestService(_, payload) {
    try {
      const response = await apolloClient.mutate({
        // Same options like above
        mutation: payload.programmed_at
          ? REQUEST_PROGRAMMED_SERVICE
          : REQUEST_SERVICE,
        variables: {
          input: payload,
        },
        update: (store, { data }) => {
          if (!payload.programmed_at) {
            const d = store.readQuery({
              query: CURRENT_SERVICES,
              variables: {
                page: 1,
                first: 5,
                state_id: '',
              },
            });
            d.currentServices.data.push(data.requestService);
            store.writeQuery({
              query: CURRENT_SERVICES,
              data: d,
              variables: {
                page: 1,
                first: 5,
                state_id: '',
              },
            });
          } else {
            const d = store.readQuery({ query: PROGRAMMED_SERVICES });
            d.programmedServices.push(data.requestProgrammedService);
            store.writeQuery({ query: PROGRAMMED_SERVICES, data: d });
          }
        },
      });

      return Promise.resolve(response.data.requestService);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async calculateServiceCost(
    _,
    { coordinates, roundTrip, vehicleType, city_id }
  ) {
    try {
      const newPayload = {
        coordinates,
        round_trip: roundTrip,
        vehicle_type_id: vehicleType,
        city_id,
      };

      const response = await apolloClient.query({
        // Same options like above
        query: CALCULATE_COST,
        variables: {
          input: newPayload,
        },
      });

      const costDetails = response.data.calculateCost;

      _.commit('SET_COST_DETAILS', costDetails);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
};
