/*=========================================================================================
  File Name: main.js
  Description: main vue(js) file
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from 'vue';
import App from './App.vue';

// Vuesax Component Framework
import Vuesax from 'vuesax';
import 'material-icons/iconfont/material-icons.css'; //Material Icons
import 'vuesax/dist/vuesax.css'; // Vuesax
Vue.use(Vuesax);
// VSwatches

// axios
import axios from './axios.js';
Vue.prototype.$http = axios;

import Repository from './plugins/repositories.js';
Vue.use(Repository);

// Theme Configurations
import '../themeConfig.js';

// Globally Registered Components
import './globalComponents.js';

// Styles: SCSS
import './assets/scss/main.scss';

// Tailwind
import '@/assets/css/main.css';

// Vue Router
import router from './router';

// Vuex Store
import store from './store/store';

// Vuexy Admin Filters
import './filters/filters';

// import '@/plugins/vue-composition.js';

// mock
// import './fake-db/index.js';

// Laravel permissions
import './plugins/laravel-permissions.js';

//Google Maps
import './plugins/vue2-google-maps';

// ACL
// import acl from './acl/acl';

//Socket.io
import './plugins/socketio.js';

// Vuejs - Vue wrapper for hammerjs
import { VueHammer } from 'vue2-hammer';
Vue.use(VueHammer);

// PrismJS
import 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';

// VeeValidate
import './plugins/vee-validate.js';

// Feather font icon
require('./assets/css/iconfont.css');

import apolloClient from './plugins/vue-apollo';
import VueCompositionApi, { provide } from '@vue/composition-api';
import { ApolloClients } from '@vue/apollo-composable';
Vue.use(VueCompositionApi);

import { AgGridVue } from 'ag-grid-vue';
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss';
import VueApollo from 'vue-apollo';

Vue.component('ag-grid-vue', AgGridVue);

// LogRocket.init('udfjjk/citix-manager');

// Vue select css
// Note: In latest version you have to add it separately
// import 'vue-select/dist/vue-select.css';
// Log rocket
import '@/plugins/logrocket.js';
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  apolloProvider: new VueApollo({
    defaultClient: apolloClient,
  }),
  setup() {
    provide(ApolloClients, {
      default: apolloClient,
    });
  },
  // acl,
  render: (h) => h(App),
}).$mount('#app');
