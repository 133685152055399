export default {
  pages: {
    key: 'title',
    data: [
      {
        title: 'Solicitar servicio',
        url: '/solicitud',
        icon: 'NavigationIcon',
        is_bookmarked: true,
      },
      {
        title: 'Panel de Control',
        url: '/panel-de-control',
        icon: 'LayoutIcon',
        is_bookmarked: true,
      },
      {
        title: 'Mapa',
        url: '/mapa',
        icon: 'MapIcon',
        is_bookmarked: true,
      },
      {
        title: 'Socios',
        url: '/socios/logisticos',
        icon: 'UsersIcon',
        is_bookmarked: true,
      },
      {
        title: 'Calendario',
        url: '/calendario',
        icon: 'CalendarIcon',
        is_bookmarked: true,
      },
    ],
  },
};
