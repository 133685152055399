import gql from 'graphql-tag';

export const PLANTS = gql`
  query plants($franchise_id: Int) {
    plants(franchise_id: $franchise_id) {
      id
      name
      description
      active
      franchise {
        id
        name
        phone
      }
      availability {
        id
        monday_seats
        tuesday_seats
        wednesday_seats
        thursday_seats
        friday_seats
        saturday_seats
        sunday_seats
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
        monday_value
        tuesday_value
        wednesday_value
        thursday_value
        friday_value
        saturday_value
        sunday_value
      }
      delegations {
        id
        plant {
          id
          name
        }
      }
      start_hour
      end_hour
    }
  }
`;

export const PLANTS_PAGINATED = gql`
  query plantsPaginated(
    $franchise_id: Int
    $first: Int!
    $page: Int!
    $search: String
  ) {
    plantsPaginated(
      franchise_id: $franchise_id
      first: $first
      page: $page
      search: $search
    ) {
      paginatorInfo {
        total
        perPage
      }
      data {
        id
        name
        active
        description
        active
        franchise {
          id
          name
        }
        availability {
          id
          monday_seats
          tuesday_seats
          wednesday_seats
          thursday_seats
          friday_seats
          saturday_seats
          sunday_seats
          monday
          tuesday
          wednesday
          thursday
          friday
          saturday
          sunday
          monday_value
          tuesday_value
          wednesday_value
          thursday_value
          friday_value
          saturday_value
          sunday_value
        }
        start_hour
        end_hour
      }
    }
  }
`;

export const SEARCH_PLANTS = gql`
  query plants($franchise_id: Int) {
    plants(franchise_id: $franchise_id) {
      id
      name
      description
      active
      franchise {
        id
        name
      }
      availability {
        id
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
        monday_value
        tuesday_value
        wednesday_value
        thursday_value
        friday_value
        saturday_value
        sunday_value
      }
      start_hour
      end_hour
      created_at
      updated_at
      __typename
    }
  }
`;

export const PLANT = gql`
  query plant($id: ID!) {
    plant(id: $id) {
      id
      name
      description
      franchise {
        id
        name
        phone
      }
      availability {
        id
        monday_seats
        tuesday_seats
        wednesday_seats
        thursday_seats
        friday_seats
        saturday_seats
        sunday_seats
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
        monday_value
        tuesday_value
        wednesday_value
        thursday_value
        friday_value
        saturday_value
        sunday_value
      }
      start_hour
      end_hour
      created_at
      updated_at
      __typename
    }
  }
`;

export const GET_TARIFFS = gql`
  query {
    tariffs {
      id
      city {
        name
      }
      base_price
      base_kms
      base_minutes
      additional_km_price
      additional_minute_price
      round_trip_percent
      stops_price
      vehicle_type {
        id
        name
        alias
      }
    }
  }
`;

export const GET_VEHICLE_TYPES = gql`
  query {
    vehicleTypes {
      id
      alias
    }
  }
`;

export const DELIVERYMEN_BALANCE = gql`
  query deliverymenBalance(
    $startDate: Date
    $endDate: Date
    $user_deliveryman_id: ID
    $page: Int
    $first: Int!
  ) {
    deliverymenBalance(
      startDate: $startDate
      endDate: $endDate
      user_deliveryman_id: $user_deliveryman_id
    ) {
      transactions(
        first: $first
        page: $page
        startDate: $startDate
        endDate: $endDate
        user_deliveryman_id: $user_deliveryman_id
      ) {
        paginatorInfo {
          total
          perPage
          currentPage
          count
        }
        data {
          id
          amount
          backend
          description
          type
          user_id
          deliveryman {
            id
            first_name
            last_name
            deliveryman {
              id
              code
            }
          }
          service {
            id
          }
          created_at
        }
      }
      totalTypes {
        total
        type
      }
    }
  }
`;

export const DELIVERYMEN_TRANSACTIONS = gql`
  query deliverymenTransactions(
    $startDate: Date
    $endDate: Date
    $user_deliveryman_id: ID
    $page: Int
    $first: Int!
  ) {
    deliverymenTransactions(
      startDate: $startDate
      endDate: $endDate
      user_deliveryman_id: $user_deliveryman_id
      first: $first
      page: $page
    ) {
      paginatorInfo {
        total
        perPage
      }
      data {
        id
        amount
        backend
        description
        type
        user_id
        deliveryman {
          first_name
          last_name
        }
        service {
          id
        }
        created_at
      }
    }
  }
`;

export const CALCULATE_COST = gql`
  query ($input: CalculateCostInput!) {
    calculateCost(input: $input) {
      base {
        value
      }
      distancia {
        text
        value
      }
      tiempo {
        text
        value
      }
      paradas {
        text
        value
      }
      round_trip {
        text
        value
      }
      consolidado
    }
  }
`;

export const ACCOUNTS = gql`
  query {
    accounts {
      id
      name
    }
  }
`;

export const PROGRAMMED_SERVICES = gql`
  query {
    programmedServices {
      id
      payment_method
      color
      description
      invoice
      order_value
      sender {
        name
        phone
        street
        coordinates {
          lat
          lng
        }
      }
      destinatary {
        id
        name
        phone
        street
      }

      directions {
        address
        description
        coordinates {
          lat
          lng
        }
      }
      franchise {
        id
        name
      }

      programmed_at

      deliveryman {
        id
      }
    }
  }
`;

export const FRANCHISES = gql`
  query {
    franchises {
      id
      contact_name
      address
      nit
      name
      phone
      attention_schedule
      commerce_type
      delivery_media
      coordinates {
        lat
        lng
      }
    }
  }
`;
export const ADMIN_FRANCHISES = gql`
  query ($first: Int!, $page: Int) {
    adminFranchises(first: $first, page: $page) {
      data {
        id
        franchise {
          id
          name
        }
        first_name
        last_name
        email
        phone
      }
      paginatorInfo {
        perPage
        total
      }
    }
  }
`;
export const ADMIN_FRANCHISE = gql`
  query ($id: ID!) {
    adminFranchise(id: $id) {
      id
      first_name
      last_name
      email
      phone
    }
  }
`;

export const FRANCHISES_PAGINATED = gql`
  query ($first: Int!, $page: Int!) {
    franchisesPaginated(first: $first, page: $page) {
      paginatorInfo {
        total
        perPage
      }
      data {
        id
        contact_name
        address
        nit
        name
        phone
        attention_schedule
        commerce_type
        delivery_media
      }
    }
  }
`;
export const FRANCHISE = gql`
  query ($id: ID!) {
    franchise(id: $id) {
      id
      nit
      contact_name
      address
      name
      commerce_type
      phone
      attention_schedule
      delivery_media
    }
  }
`;
export const CURRENT_SERVICES = gql`
  query (
    $state_id: ID
    $page: Int!
    $first: Int!
    $search: String
    $payment_method: payment_method_options
    $deliveryman_id: ID
  ) {
    currentServices(
      page: $page
      first: $first
      state_id: $state_id
      search: $search
      payment_method: $payment_method
      deliveryman_id: $deliveryman_id
    ) {
      paginatorInfo {
        total
        perPage
      }
      data {
        id
        payment_method
        round_trip
        state {
          id
          name
          code
          alarms {
            id
            max_time
            color
          }
        }
        directions {
          address
          description
          coordinates {
            lat
            lng
          }
        }
        destinatary {
          id
          name
          phone
          street
        }
        sender {
          id
          name
          phone
          street
        }
        stateHistorical {
          id
          created_at
          state {
            id
            name
            alarms {
              id
              max_time
              color
            }
            service_type {
              id
              name
            }
            is_done
            code
          }
        }
        deliveryman {
          id
          code
          address
          email
          document_number
          user {
            id
            first_name
            phone
            last_name
          }
        }
        description
        invoice
        order_value
        value
        parking_value
        insurance_value
        observation
        images {
          id
          url
          created_at
          deliveryman {
            code
            user {
              id
              first_name
              last_name
            }
          }
        }
        created_at
        updated_at
      }
    }
  }
`;

export const GET_ALARMS = gql`
  query ($searchText: String, $page: Int!, $first: Int!) {
    stateAlarms(searchText: $searchText, page: $page, first: $first) {
      data {
        id
        name
        max_time
        color
        state {
          id
          name
          code
        }
      }
      paginatorInfo {
        total
        perPage
      }
    }
  }
`;

export const STATE_ALARM = gql`
  query ($id: ID!) {
    stateAlarm(id: $id) {
      id
      name
      max_time
      color
      state {
        id
        name
      }
    }
  }
`;

export const SEARCH_CONTACT_BY_PHONE = gql`
  query ($phone: String) {
    searchContactByPhone(phone: $phone) {
      id
      phone
      name
      street
      coordinates {
        lat
        lng
      }
    }
  }
`;
export const ALL_STATES_SERVICES = gql`
  query {
    serviceStatuses(type_id: 1) {
      id
      name
      code
      sequence
      is_done
    }
  }
`;
export const NO_ASGINED_TO_PLANT_DELIVERYMEN = gql`
  {
    noAsginedToPlantDeliverymen {
      id
      code
      address
      user {
        id
        first_name
        last_name
        phone
        wallet {
          amount
        }
      }
      historyDeliverymanStatuses {
        id
        status
        observation
        created_at
      }
      document_number
      status
      data_status
      level
    }
  }
`;
export const DELIVERYMEN = gql`
  query ($exp_level_id: ID, $status: DeliverymanStatus) {
    deliverymen(exp_level_id: $exp_level_id, status: $status) {
      id
      code
      agreement_number
      address
      last_connection
      user {
        id
        first_name
        last_name
        phone
        wallet {
          amount
        }
      }
      city {
        id
        name
        department {
          id
          name
        }
      }
      historyDeliverymanStatuses {
        id
        status
        observation
        created_at
      }
      document_number
      status
      last_location {
        lat
        lng
      }
      data_status
      level
      profile_picture_url
      drivers_license_picture_url
      identification_document_picture_url
      property_card_picture_url
      updated_at
    }
  }
`;

export const EXP_LEVELS = gql`
  {
    exp_levels {
      id
      name
    }
  }
`;

export const VEHICLE_TYPES = gql`
  {
    vehicleTypes {
      id
      alias
      name
    }
  }
`;

export const GET_ACCOUNTS = gql`
  {
    accounts {
      id
      name
      amount
      type
    }
  }
`;

export const TRANSACTIONS = gql`
  query (
    $account_id: ID
    $first: Int!
    $page: Int!
    $startDate: Date
    $endDate: Date
    $types: [TransactionType]
  ) {
    transactions(
      account_id: $account_id
      first: $first
      page: $page
      startDate: $startDate
      endDate: $endDate
      types: $types
    ) {
      paginatorInfo {
        total
        perPage
      }
      data {
        id
        amount
        backend
        description
        type
        user_id
        deliveryman {
          id
          deliveryman {
            id
            code
          }
          first_name
          last_name
        }
        service {
          id
        }
        date
        created_at
      }
    }
  }
`;

export const GET_TOTAL_TYPES = gql`
  query (
    $account_id: ID
    $user_deliveryman_id: ID
    $startDate: Date
    $endDate: Date
    $type: TransactionType
  ) {
    getTotalTypes(
      account_id: $account_id
      user_deliveryman_id: $user_deliveryman_id
      startDate: $startDate
      endDate: $endDate
      type: $type
    ) {
      type
      total
    }
  }
`;

export const DELEGATIONS = gql`
  query (
    $first: Int!
    $page: Int!
    $environment: EnumEnvironment
    $plant_id: ID
    $search: String
  ) {
    delegations(
      first: $first
      page: $page
      environment: $environment
      plant_id: $plant_id
      search: $search
    ) {
      paginatorInfo {
        total
        perPage
      }
      data {
        id
        plant {
          id
          name
          franchise {
            id
            phone
            name
          }
        }
        deliveryman {
          id
          code
          user {
            id
            phone
            first_name
            last_name
          }
          delegations {
            environment
          }
        }
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
        environment
      }
    }
  }
`;

export const CITIES = gql`
  query ($department_id: ID) {
    cities(department_id: $department_id) {
      id
      name
      department {
        id
        name
      }
    }
  }
`;

export const DEPARTAMENTS = gql`
  {
    departments {
      id
      name
    }
  }
`;
export const DELIVERYMAN_DOCUMENT_TYPES = gql`
  {
    deliveryman_document_types {
      id
      document_name
      document_shortname
    }
  }
`;
export const DELIVERYMAN = gql`
  query ($id: ID!) {
    deliveryman(id: $id) {
      id
      code
      address
      observation
      agreement_number
      utility
      arl
      eps
      pension_fund
      document_type {
        id
        document_name
        document_shortname
      }
      user {
        id
        first_name
        last_name
        phone
        email
      }
      historyDeliverymanStatuses {
        id
        status
        observation
        created_at
      }
      document_number
      status
      data_status
      level
      city {
        id
        name
        department {
          id
          name
        }
      }
      vehicles {
        id
        plate
        annual_revision
        soat
        plate_picture_url
        picture_url
        vehicleType {
          id
          alias
          name
        }
      }
      profile_picture_url
      drivers_license_picture_url
      identification_document_picture_url
      property_card_picture_url
    }
  }
`;

export const SERVICES_HISTORY = gql`
  query servicesHistory(
    $page: Int!
    $first: Int!
    $state_id: ID
    $startDate: Date
    $endDate: Date
    $search: String
    $payment_method: payment_method_options
    $deliveryman_id: ID
  ) {
    servicesHistory(
      page: $page
      first: $first
      state_id: $state_id
      startDate: $startDate
      endDate: $endDate
      search: $search
      payment_method: $payment_method
      deliveryman_id: $deliveryman_id
    ) {
      paginatorInfo {
        total
        perPage
      }
      data {
        id
        payment_method
        round_trip
        state {
          id
          name
          code
          is_done
        }
        directions {
          address
          description
          coordinates {
            lat
            lng
          }
        }
        destinatary {
          id
          name
          phone
          street
        }
        sender {
          id
          name
          phone
          street
        }
        stateHistorical {
          id
          created_at
          state {
            id
            name
            service_type {
              id
              name
            }
            is_done
            code
          }
        }
        deliveryman {
          id
          code
          address
          email
          document_number
          user {
            id
            first_name
            phone
            last_name
          }
        }
        franchise {
          id
          name
        }
        observation
        images {
          id
          url
          created_at
          deliveryman {
            code
            user {
              id
              first_name
              last_name
            }
          }
        }
        description
        invoice
        order_value
        invoice_id
        value
        parking_value
        insurance_value
        created_at
        updated_at
      }
    }
  }
`;

export const COVERAGES = gql`
  {
    coverages {
      id
      city {
        id
        name
      }
      lupap_key
    }
  }
`;

export const COVERAGES_BY_DEPARTMENT = gql`
  query ($department_id: ID!) {
    coveragesByDepartment(department_id: $department_id) {
      id
      city {
        id
        name
      }
    }
  }
`;

export const ALL_DELIVERYMEN = gql`
  query ($exp_level_id: ID, $page: Int!, $first: Int!, $search: String) {
    allDeliverymen(
      exp_level_id: $exp_level_id
      page: $page
      first: $first
      search: $search
    ) {
      paginatorInfo {
        total
        perPage
      }
      data {
        id
        code
        agreement_number
        address
        profile_picture_url
        drivers_license_picture_url
        identification_document_picture_url
        property_card_picture_url
        historyDataStatuses {
          id
          status
          observation
        }
        historyLevels {
          id
          level
          observation
          created_at
        }
        user {
          id
          first_name
          last_name
          phone
          wallet {
            amount
          }
          point {
            id
            amount
          }
        }
        city {
          id
          name
          department {
            id
            name
          }
        }
        data_status
        level
        historyDeliverymanStatuses {
          id
          status
          observation
          created_at
        }
        document_number
        status
      }
    }
  }
`;

export const TOTAL_SERVICES = gql`
  query (
    $startDate: Date
    $endDate: Date
    $payment_method: payment_method_options
    $deliveryman_id: ID
    $statusId: ServiceStates
  ) {
    totalServices(
      startDate: $startDate
      endDate: $endDate
      payment_method: $payment_method
      deliveryman_id: $deliveryman_id
      statusId: $statusId
    ) {
      total
      total_cash
      total_credit
      total_value
      total_value_credit
      total_value_cash
    }
  }
`;

export const EFFICACY_DATA = gql`
  query (
    $startDate: Date
    $endDate: Date
    $payment_method: payment_method_options
    $deliveryman_id: ID
  ) {
    efficacyData(
      startDate: $startDate
      endDate: $endDate
      payment_method: $payment_method
      deliveryman_id: $deliveryman_id
    ) {
      average_taken
      average_arrived
      average_delivery
    }
  }
`;

export const COVERAGE_NAME = gql`
  {
    coverageName {
      id
      name
      coordinates {
        lat
        lng
      }
    }
  }
`;

export const COMPANIES = gql`
  {
    companies {
      id
      name
    }
  }
`;

export const NEIGHBORHOODS = gql`
  query ($page: Int!, $first: Int!, $search: String) {
    neighborhoods(page: $page, first: $first, search: $search) {
      paginatorInfo {
        total
        perPage
      }
      data {
        id
        name
        coordinates
        city {
          id
          name
        }
      }
    }
  }
`;

export const NEIGHBORHOOD_BY_ID = gql`
  query ($id: ID!) {
    neighborhoodById(id: $id) {
      id
      name
      coordinates
      city {
        id
        name
        department {
          id
          name
        }
      }
    }
  }
`;

export const ALLY_TRANSACTION_POINTS = gql`
  query (
    $page: Int!
    $first: Int!
    $search: String
    $user_deliveryman_id: ID
    $startDate: Date
    $endDate: Date
  ) {
    transactionPoints(
      first: $first
      page: $page
      search: $search
      user_deliveryman_id: $user_deliveryman_id
      startDate: $startDate
      endDate: $endDate
    ) {
      paginatorInfo {
        total
        perPage
      }
      data {
        id
        amount
        description
        date
        userDeliveryman {
          id
          first_name
          last_name
          deliveryman {
            id
            code
          }
        }
      }
    }
  }
`;

export const AREA_TARIFFS = gql`
  query ($page: Int!, $first: Int!, $search: String, $negotiation: ID) {
    areaTariffs(
      page: $page
      first: $first
      search: $search
      negotiation: $negotiation
    ) {
      paginatorInfo {
        total
        perPage
      }
      data {
        id
        name
        price
        city {
          id
          name
        }
        round_trip_percent
        vehicleType {
          alias
        }
        negotiation {
          id
          name
        }
      }
    }
  }
`;

export const NEGOTIATIONS = gql`
  query ($page: Int!, $first: Int!, $search: String) {
    negotiations(page: $page, first: $first, search: $search) {
      paginatorInfo {
        total
        perPage
      }
      data {
        id
        name
        company {
          id
          name
        }
        round_cost
      }
    }
  }
`;

export const NEGOTIATION = gql`
  query ($id: ID!) {
    negotiation(id: $id) {
      id
      name
      company {
        id
        name
      }
      round_cost
    }
  }
`;

export const ALL_NEGOTIATIONS = gql`
  {
    allNegotiations {
      id
      name
    }
  }
`;

export const AREA_TARIFF = gql`
  query ($id: ID!) {
    areaTariff(id: $id) {
      id
      name
      price
      city {
        id
        name
      }
      round_trip_percent
      vehicleType {
        id
        alias
      }
      negotiation {
        id
        name
      }
      polygon {
        lng
        lat
        zAxis
      }
    }
  }
`;

export const INVENTORY = gql`
  query ($page: Int!, $first: Int!, $search: String) {
    inventory(page: $page, first: $first, search: $search) {
      paginatorInfo {
        total
        perPage
      }
      data {
        id
        name
        description
        price
        stock
        creatorUser {
          first_name
          last_name
        }
        company {
          name
        }
        history {
          id
          type
          description
          creatorUser {
            first_name
            last_name
          }
          created_at
        }
        created_at
        updated_at
      }
    }
  }
`;

export const INVENTORY_ITEM = gql`
  query ($id: ID!) {
    inventoryItem(id: $id) {
      id
      name
      description
    }
  }
`;

export const INVENTORY_HISTORY = gql`
  query (
    $page: Int!
    $first: Int!
    $search: String
    $startDate: Date
    $endDate: Date
  ) {
    inventoryHistory(
      page: $page
      first: $first
      search: $search
      startDate: $startDate
      endDate: $endDate
    ) {
      paginatorInfo {
        total
        perPage
      }
      data {
        id
        type
        description
        creatorUser {
          first_name
          last_name
        }
        inventory {
          name
          description
          price
          stock
          company {
            name
          }
        }
        created_at
      }
    }
  }
`;

export const INVENTORY_SALES = gql`
  query (
    $page: Int!
    $first: Int!
    $search: String
    $startDate: Date
    $endDate: Date
  ) {
    inventorySales(
      page: $page
      first: $first
      search: $search
      startDate: $startDate
      endDate: $endDate
    ) {
      paginatorInfo {
        total
        perPage
      }
      data {
        id
        type
        description
        cash
        points
        paid
        amount
        creatorUser {
          first_name
          last_name
        }
        inventory {
          name
          description
          price
          stock
        }
        company {
          name
        }
        deliveryman {
          id
          user {
            first_name
            last_name
          }
        }
        created_at
      }
    }
  }
`;

export const ALL_INVENTORIES = gql`
  {
    allInventories {
      id
      name
      stock
    }
  }
`;

export const BANK_ACCOUNTS = gql`
  query bankAccounts(
    $page: Int!
    $first: Int!
    $startDate: Date
    $endDate: Date
    $search: String
    $status: BankAccountStatus
    $deliveryman_id: ID
  ) {
    bankAccounts(
      page: $page
      first: $first
      startDate: $startDate
      endDate: $endDate
      search: $search
      status: $status
      deliveryman_id: $deliveryman_id
    ) {
      paginatorInfo {
        total
        perPage
      }
      data {
        id
        name
        bank
        number
        document
        image
        status
        deliveryman {
          code
          user {
            first_name
            last_name
          }
        }
        created_at
        updated_at
      }
    }
  }
`;

export const EXCHANGE_BALANCES = gql`
  query (
    $page: Int!
    $first: Int!
    $search: String
    $user_deliveryman_id: ID
    $startDate: Date
    $endDate: Date
  ) {
    exchangeBalances(
      first: $first
      page: $page
      search: $search
      user_deliveryman_id: $user_deliveryman_id
      startDate: $startDate
      endDate: $endDate
    ) {
      paginatorInfo {
        total
        perPage
      }
      data {
        id
        amount
        description
        date
        type
        userDeliveryman {
          id
          first_name
          last_name
          deliveryman {
            id
            code
          }
        }
      }
    }
  }
`;

export const WITHDRAW_REQUESTS_HISTORY = gql`
  query withdrawRequestHistory(
    $page: Int!
    $first: Int!
    $startDate: Date
    $endDate: Date
    $search: String
    $status: WithdrawRequestStatus
    $deliveryman_id: ID
  ) {
    withdrawRequestHistory(
      page: $page
      first: $first
      startDate: $startDate
      endDate: $endDate
      search: $search
      status: $status
      deliveryman_id: $deliveryman_id
    ) {
      paginatorInfo {
        total
        perPage
      }
      data {
        id
        status
        amount
        image
        bankAccount {
          name
          bank
          number
          document
        }
        deliveryman {
          code
          user {
            first_name
            last_name
          }
        }
        account {
          id
          name
        }
        created_at
        updated_at
        approved_at
      }
    }
  }
`;

export const WITHDRAW_REQUESTS_HISTORY_TOTAL = gql`
  query withdrawRequestHistoryTotal($status: WithdrawRequestStatus) {
    withdrawRequestHistoryTotal(status: $status) {
      total
    }
  }
`;

export const INVOICES = gql`
  query invoices(
    $search: String
    $status: InvoiceStatus
    $senders: [ID]
    $year: Int!
    $month: Int!
    $first: Int!
    $page: Int!
  ) {
    invoices(
      search: $search
      status: $status
      senders: $senders
      year: $year
      month: $month
      first: $first
      page: $page
    ) {
      paginatorInfo {
        total
        perPage
      }
      data {
        id
        status
        start_date
        end_date
        credit_value
        income
        bank_withholding
        observation
        image
        paid_at
        cancelled_at
        created_at
      }
    }
  }
`;

export const TOTAL_INVOICES = gql`
  query totalInvoices(
    $search: String
    $status: InvoiceStatus
    $senders: [ID]
    $year: Int!
    $month: Int!
  ) {
    totalInvoices(
      search: $search
      status: $status
      senders: $senders
      year: $year
      month: $month
    ) {
      invoiced
      income
      bank_withholding
      credit_services
    }
  }
`;

export const CONTACTS = gql`
  query {
    allContacts {
      id
      name
    }
  }
`;

export const CONTACTS_IN_DATES = gql`
  query allContactsInDates($startDate: Date, $endDate: Date) {
    allContactsInDates(startDate: $startDate, endDate: $endDate) {
      id
      name
    }
  }
`;

export const CALCULATE_INVOICE = gql`
  query calculateInvoice(
    $start_date: Date!
    $end_date: Date!
    $senders: [ID!]!
  ) {
    calculateInvoice(
      start_date: $start_date
      end_date: $end_date
      senders: $senders
    ) {
      calculatedInvoice
    }
  }
`;
