/* eslint-disable no-empty */

import state from './state';
import mutations from './mutations';
import actions from './actions';

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
};
