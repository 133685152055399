// axios
import axios from 'axios';

const baseURL =
  process.env.NODE_ENV !== 'development'
    ? process.env.VUE_APP_API_LOCATION
    : process.env.VUE_APP_API_LOCATION;

export default axios.create({
  baseURL: baseURL,
  // You can add your headers here
});
