// Google Maps
import Vue from 'vue';
import * as VueGoogleMaps from 'vue2-google-maps';
Vue.use(VueGoogleMaps, {
  load: {
    // Add your API key here
    key: process.env.VUE_APP_GOOGLEMAP_KEY,
    libraries: 'places', // This is required if you use the Auto complete plug-in
  },
});
