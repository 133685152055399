/* eslint-disable no-empty */
import { CURRENT_SERVICES } from '@/graphql/queries';
import apolloClient from '@/plugins/vue-apollo';
export default {
  async getCurrentServices() {
    try {
      const res = await apolloClient.query({
        query: CURRENT_SERVICES,
        variables: {
          page: 1,
          first: 5,
          state_id: '',
        },
      });

      const currentServices = res.data.currentServices;
      // commit('SET_CURRENT_SERVICES', currentServices);
      return currentServices;
    } catch (error) {}
  },
};
