export default {
  SET_DEPARTMENTS(state, data) {
    state.departments = data;
  },
  SET_CITIES(state, data) {
    state.cities = data;
  },
  SET_COVERAGES(state, data) {
    state.coverageCities = data;
  },
};
