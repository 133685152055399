import UserRepository from '@/repositories/UserRepository';
import CitiesRepository from '@/repositories/CitiesRepository';
import UrbanosRepository from '@/repositories/UrbanosRepository';
import GeocoderRepository from './GeocoderRepository';
import RequestRepository from '@/repositories/RequestRepository';
import ServicesRepository from '@/repositories/ServicesRepository';
import DeliverymenRepository from './DeliverymenRepository';
import AccountsRepository from './AccountsRepository';
export default ($axios) => ({
  user: UserRepository($axios),
  cities: CitiesRepository($axios),
  urbanos: UrbanosRepository($axios),
  request: RequestRepository($axios),
  services: ServicesRepository($axios),
  deliverymen: DeliverymenRepository($axios),
  accounts: AccountsRepository($axios),
  geocoder: GeocoderRepository($axios),
});
