export default {
  SET_DELIVERYMEN(state, data) {
    state.deliverymen = data;
  },
  SET_ALLY_MARKERS(state, data) {
    state.allyMarkers = data.map((d) => {
      return {
        position: { lat: d.last_location.lat, lng: d.last_location.lng },
        visible: true,
        angle: 60,
        ...d,
      };
    });
  },
  UPDATE_ALLY_LOCATION(state, ally) {
    const marker = state.allyMarkers.find((m) => m.code == ally.code);

    if (marker) {
      marker.position = ally.last_location;
    }
  },
};
