/* eslint-disable no-empty */
import { DELIVERYMEN } from '@/graphql/queries';
import apolloClient from '@/plugins/vue-apollo';

export default {
  async listAllDeliverymen({ commit }) {
    try {
      const res = await this._vm.$repositories.deliverymen.listAllDeliverymen();

      const { status, data } = res;
      if (status === 200) {
        commit('SET_DELIVERYMEN', data);
      }
    } catch (error) {}
  },

  async getAllies({ commit }) {
    const response = await apolloClient.query({
      query: DELIVERYMEN,
      variables: {
        status: 'ENABLED',
      },
    });

    const allies = response.data.deliverymen;
    commit('SET_ALLY_MARKERS', allies);
  },
};
