import Vue from 'vue';
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  localize,
} from 'vee-validate';

import {
  required,
  email,
  min,
  alpha_num,
  alpha_spaces,
  alpha,
  numeric,
  min_value,
  max_value,
  max,
  confirmed,
  alpha_dash,
} from 'vee-validate/dist/rules';
import es from 'vee-validate/dist/locale/es.json';
import en from 'vee-validate/dist/locale/en.json';

import PhoneNumber from 'awesome-phonenumber';

const phoneNumber = {
  message(fieldName) {
    return `${fieldName} no es un número valido`;
  },
  validate(value) {
    return new Promise((resolve) => {
      let phone = new PhoneNumber(value, 'CO');

      resolve({ valid: phone.isValid() });
    });
  },
};

extend('alpha_dash', alpha_dash);

extend('confirmed', confirmed);

extend('phone', phoneNumber);

// Install required rule.
extend('required', required);

// Install email rule.
extend('email', email);

// Install min rule.
extend('min', min);

// Install max rule.
extend('max', max);

//Install min_value rule
extend('min_value', min_value);
//Install max_value
extend('max_value', max_value);
//Install numeric rule
extend('numeric', numeric);

//Install alpha rule
extend('alpha', alpha);

//Install alpha_num rule
extend('alpha_num', alpha_num);

// Install alpha_spaces rule
extend('alpha_spaces', alpha_spaces);
// Install English and Spanish localizations.
localize({
  en: {
    messages: en.messages,
    names: {
      email: 'E-mail Address',
      password: 'Password',
    },
    fields: {
      password: {
        min: '{_field_} is too short, you want to get hacked?',
      },
    },
  },
  es: {
    messages: es.messages,
  },
});

let LOCALE = 'es';
localize(LOCALE);
// A simple get/set interface to manage our locale in components.
// This is not reactive, so don't create any computed properties/watchers off it.
Object.defineProperty(Vue.prototype, 'locale', {
  get() {
    return LOCALE;
  },
  set(val) {
    LOCALE = val;
    localize(val);
  },
});

// Register it globally
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
