export default {
  departments: [],
  cities: [],
  costDetails: {
    base: { value: 0 },
    distancia: { text: '0 km', value: 0 },
    tiempo: { text: '0 min', value: 0 },
    paradas: { text: 0, value: 0 },
    round_trip: { text: false, value: 0 },
    consolidado: 0,
  },
};
