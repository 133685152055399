const resource = 'api';
export default ($http) => ({
  getCitiesByDepartment(departamentId) {
    return $http.get(`${resource}/cities/${departamentId}`, {});
  },

  getDepartaments() {
    return $http.get(`${resource}/departments`);
  },
});
