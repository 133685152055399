/* eslint-disable no-empty */
import apolloClient from '@/plugins/vue-apollo';
import { COVERAGES_BY_DEPARTMENT } from '@/graphql/queries';
export default {
  async getDepartments({ commit }) {
    try {
      const res = await this._vm.$repositories.cities.getDepartaments();

      const { status, data } = res;
      if (status === 200) {
        commit('SET_DEPARTMENTS', data);
      }
    } catch (error) {}
  },
  async getCitiesByDepartment({ commit }, id) {
    try {
      const res = await this._vm.$repositories.cities.getCitiesByDepartment(id);

      const { status, data } = res;
      if (status === 200) {
        commit('SET_CITIES', data);
      }
    } catch (error) {}
  },
  async getCoveragesByDepartment({ commit }, id) {
    try {
      const { data } = await apolloClient.query({
        query: COVERAGES_BY_DEPARTMENT,
        variables: {
          department_id: id,
        },
      });
      console.log(
        '🚀 ~ file: actions.js ~ line 33 ~ getCoveragesByDepartment ~ data',
        data
      );

      commit('SET_COVERAGES', data.coveragesByDepartment);
    } catch (error) {}
  },
};
