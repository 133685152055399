import { ApolloLink } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
// import Pusher from 'pusher-js';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createUploadLink } from 'apollo-upload-client';
import EventBus from './event-bus';
// import PusherLink from './pusher.link';
import router from './router/index';
function logout() {
  // If JWT login
  if (localStorage.getItem('accessToken')) {
    localStorage.removeItem('accessToken');
    router.push('/iniciar-sesion').catch(() => {});
  }

  // Change role on logout. Same value as initialRole of acj.js
  // this.$acl.change('public');
  localStorage.removeItem('userInfo');

  // This is just for demo Purpose. If user clicks on logout -> redirect
  router.push('/iniciar-sesion').catch(() => {});
}

// import EchoLink from './echo.link'
// const accessToken = window.localStorage.getItem('accessToken');

// const pusherLink = new PusherLink({
//   pusher: new Pusher(`${process.env.VUE_APP_PUSHER_APP_KEY}`, {
//     cluster: 'us2',
//     authEndpoint: `${process.env.VUE_APP_API_LOCATION}/graphql/subscriptions/auth`,
//     enabledTransports: ['ws', 'wss'],
//     wsHost: `${process.env.VUE_APP_WEBSOCKET_URL}`,
//     wsPort: process.env.VUE_APP_WEBSOCKET_PORT,
//     wssPort: process.env.VUE_APP_WEBSOCKET_PORT,
//     forceTLS: true,
//     auth: {
//       headers: {
//         authorization: accessToken ? `Bearer ${accessToken}` : null,
//       },
//     },
//     disableStats: true,
//   }),
// });

const httpOptions = {
  uri: `${process.env.VUE_APP_API_LOCATION}/graphql`,
};

// const httpLink = new HttpLink({
//   uri: `${process.env.API_LOCATION}/realtime`,
// })
const httpLink = ApolloLink.split(
  (operation) => operation.getContext().hasUpload,
  createUploadLink(httpOptions),
  new HttpLink(httpOptions)
);

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('accessToken');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ debugMessage }) => {
      // If token invalid log in
      if (debugMessage == 'Unauthenticated.') {
        logout();
      }
    });
    graphQLErrors.map(({ message, locations, path }) => {
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );
      EventBus.$emit('error', message);
    });
  }

  if (networkError) {
    // Add something like this to set the error message to the one from the server response
    networkError.message = networkError.result.errors[0].debugMessage;
    console.log(`[Network error]: ${networkError}`);
  }
});
// const echoLink = new EchoLink()
const link = authLink.concat(ApolloLink.from([errorLink, httpLink]));

export default function () {
  return {
    link,
    defaultHttpLink: false,
    cache: new InMemoryCache({
      // addTypename: false4,
    }),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
      },
      query: {
        fetchPolicy: 'network-only',
      },
    },
  };
}
