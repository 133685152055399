const resource = '/api/accounts';
export default ($http) => ({
  listAccounts() {
    return $http.get(`${resource}`);
  },
  storeAccount({ name, type }) {
    return $http.post(`${resource}`, { name, type });
  },
  deleteAccount(id, specialKey) {
    return $http.delete(`${resource}/${id}`, { specialKey: specialKey });
  },
});
