import jwt from '../../http/requests/auth/jwt/index.js';

import router from '@/router';
import axios from '@/axios';

export default {
  async registerUser({ dispatch }, payload) {
    try {
      dispatch('loginJWT', {
        register_type: 'admin',
        ...payload,
      }).then(() => {
        payload.closeAnimation();
        payload.notify({
          title: 'Account Created',
          text: 'You are successfully registered!',
          iconPack: 'feather',
          icon: 'icon-check',
          color: 'success',
        });
      });
    } catch (error) {
      if (payload.closeAnimation) payload.closeAnimation();
      payload.notify({
        title: 'Error',
        text: error.message,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'danger',
      });
    }
  },
  updateUsername({ commit }, payload) {
    payload.user
      .updateProfile({
        displayName: payload.displayName,
      })
      .then(() => {
        // If username update is success
        // update in localstorage
        let newUserData = Object.assign({}, payload.user.providerData[0]);
        newUserData.displayName = payload.displayName;
        commit('UPDATE_USER_INFO', newUserData, { root: true });

        // If reload is required to get fresh data after update
        // Reload current page
        if (payload.isReloadRequired) {
          router.push(router.currentRoute.query.to || '/');
        }
      })
      .catch((err) => {
        payload.notify({
          time: 8800,
          title: 'Error',
          text: err.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
      });
  },

  // JWT
  loginJWT({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      jwt
        .login(payload.userDetails, payload.register_type)
        .then((response) => {
          // If there's user data in response
          if (response.data) {
            // Navigate User to homepage
            // router.push(router.currentRoute.query.to || '/');

            // Set accessToken

            localStorage.setItem('accessToken', response.data.access_token);

            // Update user details
            commit('UPDATE_USER_INFO', response.data.user, { root: true });

            // Set bearer token in axios
            commit('SET_BEARER', response.data.access_token);

            dispatch('getPermissions').then(() => {
              router.push(router.currentRoute.query.to || '/').catch(() => {
                /*Elimina error en consola*/
              });
            });

            resolve(response);
          } else {
            reject({ message: 'Wrong Email or Password' });
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // Dont use
  registerUserJWT({ commit }, payload) {
    const {
      displayName,
      displaySurname,
      email,
      password,
      confirmPassword,
      masterPassword,
    } = payload.userDetails;

    return new Promise((resolve, reject) => {
      // Check confirm password
      if (password !== confirmPassword) {
        reject({ message: "Password doesn't match. Please try again." });
      }

      jwt
        .registerUser(
          displayName,
          displaySurname,
          email,
          password,
          masterPassword
        )
        .then((response) => {
          // Update data in localStorage
          localStorage.setItem('accessToken', response.data.access_token);

          if (this._vm.$http) {
            this._vm.$http.setHeader(
              'Authorization',
              'Bearer ' + response.data.access_token
            );
          }

          commit('SET_BEARER', response.data.access_token);
          commit('UPDATE_USER_INFO', response.data.user, { root: true });
          // Redirect User
          router.push(router.currentRoute.query.to || '/');
          // dispatch('getPermissions').then(() => {
          //   router.push(router.currentRoute.query.to || '/').catch(() => {
          //     /*Elimina error en consola*/
          //   });
          // });

          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  // Dont used yet
  fetchAccessToken() {
    return new Promise((resolve) => {
      jwt.refreshToken().then((response) => {
        resolve(response);
      });
    });
  },
  async getPermissions() {
    let user = localStorage.getItem('userInfo');
    if (!user) {
      return;
    }
    user = JSON.parse(user);

    const initialPermissions = user.permission_names;
    const initialRoles = user.role_names;

    console.log({
      permissions: initialPermissions,
      roles: initialRoles,
    });

    this._vm.$gates.setPermissions(initialPermissions);
    this._vm.$gates.setRoles(initialRoles);

    const { data: roles } = await axios.get('/api/roles');
    const { data: permissions } = await axios.get('/api/permissions');
    this._vm.$gates.setPermissions(permissions);
    this._vm.$gates.setRoles(roles);
  },
};
